/*----------------------------
    Video Popup Styles 
-------------------------------*/
button {
  &.video-popup {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translate(-6px, -9px);
      -ms-transform: translate(-6px, -9px);
      transform: translate(-6px, -9px);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 15px solid rgba(34, 139, 34, 1);
    }
    &:hover {
      -webkit-transform: scale3d(1.15, 1.15, 1.15);
      transform: scale3d(1.15, 1.15, 1.15);
    }
  }
}

button {
  &.video-popup {
    &.position-top-center {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 0 none;
      width: 110px;
      height: 110px;
      margin-left: -55px;
      margin-top: -55px;
      transition: all 1s cubic-bezier(0, 0, 0.2, 1);
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
      @media #{$sm-layout} {
        width: 70px;
        height: 70px;
        margin-left: -35px;
        margin-top: -35px;
      }
      &:hover {
        transform: scale3d(1.15, 1.15, 1.15);
      }
      &.md-size {
        width: 80px;
        height: 80px;
        margin-left: -40px;
        margin-top: -40px;
      }
    }
    &.theme-color {
      background: rgba(34, 139, 34, 1);
      border-color: rgba(34, 139, 34, 1);
      &::before {
        border-left: 15px solid #ffffff;
      }
    }
    &.black-color {
      background: $black-color;
      border-color: $black-color;
      &::before {
        border-left: 15px solid #ffffff;
      }
    }
  }
}
