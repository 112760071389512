/*=====================
    Pagination Styles 
=======================*/

.rn-pagination {
  ul {
    &.page-list {
      @extend %liststyle;
      li {
        display: inline-block;
        padding: 5px;
        a {
          padding: 0 10px;
          display: block;
          text-align: center;
          line-height: 38px;
          min-width: 42px;
          height: 42px;
          border: 2px solid var(--color-border);
          background: #fff;
          color: var(--color-border);
          position: relative;
          z-index: 2;
          font-weight: 600;
          border-radius: 4px;
          &:hover {
            color: #ffffff;
            border-color: rgba(34, 139, 34, 1);
            background-color: rgba(34, 139, 34, 1);
          }
        }
        &.active {
          a {
            color: #ffffff;
            border-color: rgba(34, 139, 34, 1);
            background-color: rgba(34, 139, 34, 1);
          }
        }
      }
    }
  }
}
.line-separator {
  border-bottom: 1px solid #ebebeb;
}
